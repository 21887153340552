<template>
  <div id="app">
    <router-view name="header" ref="header"/>
    <div class="app-content">
      <el-scrollbar ref="mainScroller" class="main-scroll">
        <div :class="['home' ,{'is-collapse': isCollapse}]">
          <div class="left">
            <!--  左侧菜单 -->
            <router-view name="menu" />
          </div>

          <div class="main">
            <!-- tab页-->
            <router-view name="pageList" />
            <!-- <page-list></page-list> -->
            <!-- 主体部分 :params="$route.params" -->
            <keep-alive>
              <router-view name="content" :key="$route.path"  />
            </keep-alive>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <el-dialog
        title="验证码校验"
        :visible.sync="showCaptchaDialog"
        :close-on-click-modal="false"
        :before-close="handleCaptchaClose"
        :append-to-body="true"
        width="30%"
    >
      <el-form
          ref="captchaForm"
          :model="info"
          label-width="100px"
          label-position="right"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="手机号"  prop="mobile">
              <el-input v-model="mobile"  :disabled="true" style="width:50%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="row" style='display:flex;'>
            <el-form-item label="短信验证码" prop="verifyCode">
              <el-input v-model="info.verifyCode" clearable  >
                <el-button v-if="verifyCodeShow" slot="append" type="primary" @click="sendVerifyCode"
                           style="background-color:#409eff;color:white">获取验证码
                </el-button>
                <el-button v-else slot="append" type="primary" disabled>{{ waitSec }}s重新获取</el-button>
              </el-input>

            </el-form-item>

          </div>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div>验证通过后，有效时间为4小时</div>
          </el-col>
        </el-row>
       </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCaptchaClose">取 消</el-button>
        <el-button type="primary" @click="submitCaptcha">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from './plugins/http/eventBus.js';

export default {
  computed: {
    ...mapGetters({
      sp: 'user/shipper',
      isCollapse: "layout/isCollapse",
      sysConfig: "config/sysConfig"
    })
  },
  data() {
    return {
      showCaptchaDialog: false,
      mobile:'',
      realMobile:'',
      info: {
        verifyCode:''
      },
      verifyCodeShow:true,
      waitSec: 60,
        anncInfo: {
          title: "",
          category: "",
          status: "",
          content: ""
        },
        anncDialogVisible: false,
        hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
        saasImg: require("@/assets/saas_logo.png"),
    };
  },
  methods: {
    ...mapActions(["user/checkLogin", "config/checkConfig","layout/setScrollTop"]),
    handleScroll(){
      let scrollbarEl = this.$refs.mainScroller.wrap
      scrollbarEl.onscroll = () => {
         this["layout/setScrollTop"](scrollbarEl.scrollTop)
      }
    },

    submitCaptcha() {
     if(!this.info.verifyCode){
       return  this.$message.error("请输入验证码");
     }
     if(!this.realMobile){
       return  this.$message.error("手机号码异常");
     }

      const params = {
        'smsCode': this.info.verifyCode,
        'mobile': this.realMobile
      };
      aEntityAPI.request("FnWhiteIpService", "checkSmsCode", params).then(response => {
        let res = response.data
        if (res.success) {
          this.$message.success('校验成功！');
          // 关闭弹窗
          this.info={
            verifyCode:''
          }
          this.showCaptchaDialog = false;
        } else {
          this.$message.error(res.msg);
        }
      })



    },
    handleCaptchaClose(done) {
      this.showCaptchaDialog = false;
      done();
    },
    showCaptcha() {
      this.getShipperUserMobile()

    },
    showMsgCha() {
      this.$message.warning('当前登录IP异常禁止操作')
    },
    //发送验证码
    sendVerifyCode() {
      if(!this.realMobile){
        return this.$message.warning('请输入手机号')
      }
      if (60 === this.waitSec) {
        this.verifyCodeShow = false
        aEntityAPI.request("FnWhiteIpService", "sendSmsCode", {mobile: this.realMobile}).then(res => {
          if (res.data.success) {
            this.$message.success('已发送短信验证码')
            let counter = () => {
              this.waitSec = this.waitSec - 1;
              if (this.waitSec > 0) {
                countFunc();
              } else {
                this.verifyCodeShow = true;
                this.waitSec = 60;
              }
            }
            const countFunc = function () {
              setTimeout(counter, 1000);
            }
            countFunc();
          } else {
            this.$message.error(res.data.msg)
            this.verifyCodeShow = true;
            this.waitSec = 60
          }
        })
      }
    },
    getShipperUserMobile(){
      let userId = ''

        if (this.userinfo) {
          userId = this.userinfo.id
        }

      if (!userId) {
        return this.$message.error('用户不存在！')
      }
      let _this = this;
      aEntityAPI.get("User", userId, 1).then(res => {
        let resd = res.data
        if (resd.success) {
          _this.realMobile = resd.data.mobile
          _this.mobile =  _this.realMobile.substr(0, 3) + "****" +  _this.realMobile.substr(-4)
          _this.$message.warning('登录IP异常，请输入验证码')
          _this.showCaptchaDialog = true;
        } else {
          _this.$message.error(resd.msg);
        }
      });
    },

    queryTaskCount(){
        // alert('app.testInvoke')
        this.$refs.header.queryTaskCount();
    }
  },
  watch: {
    // sysConfig: function(nval, oval) {
    //   if (nval && nval.logoFile && nval.logoFile.filePath) {
    //     var link = document.createElement("link");
    //     link.type = "image/x-icon";
    //     link.rel = "shortcut icon";
    //     link.href =  this.__ftpPath + nval.logoFile.filePath;
    //     document.getElementsByTagName("head")[0].appendChild(link);
    //   }
    // }

      sysConfig: function (nval, oval) {
          if (nval && nval.logoFile && nval.logoFile.filePath) {
              var link = document.createElement("link");
              link.type = "image/x-icon";
              link.rel = "shortcut icon";

              var serverHost = window.location.host;
              if (serverHost.indexOf("hrsaas")>-1 || serverHost.indexOf("saas.gz-vip.com") >-1){
                  link.href = this.hrsaasImg;
              } else if (serverHost.indexOf("saas")>-1) {
                  link.href = this.saasImg;
              }else {
                  link.href = this.__ftpPath + nval.logoFile.filePath;
              }
              document.getElementsByTagName("head")[0].appendChild(link);
          }
      }
  },
  created() {
    EventBus.$on('showCaptcha', this.showCaptcha);
    EventBus.$on('showMsgCha', this.showMsgCha);
  },
  beforeDestroy() {
    EventBus.$off('showCaptcha', this.showCaptcha);
    EventBus.$off('showMsgCha', this.showMsgCha);
  },
  mounted() {
    this["user/checkLogin"]();
    this["config/checkConfig"]();

    //监听滚动
     this.handleScroll()
  }
};
</script>
<style>
html,
body {
  height: 100%;
}

body {
  overflow: hidden;
}

#app {
  height: 100%;
  overflow: hidden;
}

.app-content {
  height: 100%;
  padding: 0;
  margin-top: 0;
  height: 100%;
  min-height: auto;
}

.home {
  position: relative;
  top: 51px;
  position: relative;
  height: 100%;
}

.left {
  position: fixed;
  width: 180px;
  left: 0;
  top: 50px;
  bottom: 0px;
  z-index: 9;
}

.home.is-collapse > .left {
  width: 64px;
}

.main {
  position: relative;
  left: 195px;
  right: 15px;
  top: 45px;
  bottom: 15px;
  width: calc(100% - 210px);
}

.main-scroll {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.home.is-collapse > .main {
  left: 79px;
  width: calc(100% - 94px);
}

.main-scroll > .el-scrollbar__wrap {
  overflow-x: hidden;
}

.main-scroll > .el-scrollbar__wrap > .el-scrollbar__view .main > div {
  min-width: 1170px;
}

.main-scroll > .el-scrollbar__wrap > .el-scrollbar__view .main > div.grid-wrap {
  min-width: unset;
}

.main-scroll
  > .el-scrollbar__wrap
  > .el-scrollbar__view
  .main
  > div.unset-min-width {
  min-width: unset;
}

.main-scroll > .el-scrollbar__bar.is-horizontal:hover {
  height: 10px;
}

.main-scroll > .el-scrollbar__bar.is-vertical:hover {
  width: 10px;
}
</style>